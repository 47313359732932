import {AxiosPromise, AxiosResponse} from 'axios'
import * as React from 'react'
import {Spinner} from '../components/spinner/Spinner'
import {API_URL} from '../constants/AppConstants'
import {useAsync} from '../hooks/useAsync'
import {User} from '../models/User'
import {callApi, getApi, getAppOrigin, redirectTo} from '../utils/util'

async function callUserApi(): Promise<AxiosPromise<User>> {
  const user = await callApi<User>(`/auth/user`)

  // Feature lock
  const resourceValue = await callApi<{
    status: string
    message: string
    data: {
      resourceValue: boolean
    }
  }>(`/header/feature-lock`)

  user.data.data.data.resourceValue = resourceValue.data.data.resourceValue

  return new Promise(resolve => {
    resolve(user)
  })
}

function callLogoutApi(): Promise<AxiosResponse> {
  return getApi(`/auth/logout`)
}

async function authenticateUser(): Promise<User | null> {
  const excludedPath = ['cxportal', 'email-unsubscribe']
  const location = window.location
  const startingPathname = location.pathname.split('/')[1]

  const appOrigin = getAppOrigin(location)

  if (!excludedPath.includes(startingPathname)) {
    return callUserApi()
      .then(async response => {
        return (await response).data
      })
      .catch(error => {
        if (error?.response.status === 403) {
          redirectTo(`${API_URL}/api/auth/oauth?appOrigin=${appOrigin}`)
          return null
        }
        throw error
      })
  } else {
    return null
  }
}

interface IAuthContext {
  user: User | null
  logout?: Function
  login(user: User): void
}

const AuthContext = React.createContext<IAuthContext>({
  login: () => {},
  user: null,
})
AuthContext.displayName = 'AuthContext'

interface Props {
  children: React.ReactNode
}

const AuthProvider: React.FC<Props> = ({...props}) => {
  const [user, setUser] = React.useState<User | null>(null)
  const {status, value, error} = useAsync<User | null>(authenticateUser)
  const isLoading = status === 'pending' || status === 'idle'
  const isSuccess = status === 'success'
  const isError = status === 'error'

  React.useEffect(() => {
    setUser(value)
  }, [value])

  const logout = React.useCallback(() => {
    callLogoutApi()
  }, [])

  const login = React.useCallback((user: React.SetStateAction<User | null>) => {
    setUser(user)
  }, [])

  const contextValue = React.useMemo(
    () => ({user, login, logout}),
    [user, login, logout],
  )

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <div>Oops an error occurred. {error?.message}</div>
  }

  if (isSuccess) {
    return <AuthContext.Provider value={contextValue} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth(): {user: User; logout: Function; login: Function} {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  const {user, logout, login} = context
  if (!user || !logout) {
    throw new Error(
      'Member not yet fetched. Consider using "useOptAuth" ' +
        'if you expect member to be not initialized.',
    )
  }

  return {user, logout, login}
}

function useOptAuth(): IAuthContext {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

function useLogout(): Function | undefined {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useLogout must be used within a AuthProvider`)
  }

  return context.logout
}
function useLogin(): (user: User) => void {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useLogin must be used within a AuthProvider`)
  }

  return context.login
}

export {AuthProvider, useAuth, useOptAuth, useLogout, useLogin}
