import i18next from 'i18next'
import {format} from 'date-fns'
import axios, {AxiosError, AxiosPromise, AxiosRequestConfig} from 'axios'
import {API_URL} from '../constants/AppConstants'
import {Ticket} from '../screens/ticket-list/TicketList'
import {
  IconDefinition,
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons'
import {Dashboard} from '../models/Dashboard.interface'

const {t} = i18next

export const redirectTo = (url: string): void => {
  window.location.replace(url)
}
export function callApi<T>(
  endpoint: string,
  config?: AxiosRequestConfig,
): AxiosPromise<T> {
  return axios({
    url: `${API_URL}/api${endpoint}`,
    withCredentials: true,
    ...config,
  })
}

export async function getApi<T>(
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'GET', ...config})
  return response.data
}

export async function postApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'POST', data, ...config})
  return response.data
}

export async function putApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'PUT', data, ...config})
  return response.data
}

export async function patchApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {
    method: 'PATCH',
    data,
    ...config,
  })

  return response.data
}

export async function deleteApi<T>(
  endpoint: string,
  data: unknown = {},
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {
    method: 'DELETE',
    data,
    ...config,
  })

  return response.data
}

export class PaginationListParameters {
  pageNumber: number = 1
  perPage: number = 10
  searchTerm?: string
  searchQuery?: string
}

interface PagerDto {
  pageNumber: number
  perPage: number
}

interface PagerOptions {
  readonly pagerDto: PagerDto
  readonly totalCount: number
}

export interface ApiResponse<T> {
  data: T
}

export interface ApiListResponse<T> {
  pagerOptions?: PagerOptions
  data: T[]
}

export interface ApiTicketDetailsResponse {
  data: Ticket
}

export const toCapitalize = (word: string): string => {
  const lower = word?.toLowerCase()
  return word?.charAt(0).toUpperCase() + lower?.slice(1)
}

export const truncateWithEllipses = (text: string, max: number): string => {
  return text.substring(0, max - 1) + (text.length > max ? '...' : '')
}

export const objectToQueryParams = (obj: object): string => {
  const queryParams = Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')

  return queryParams
}

export const queryParamsToObject = (qParams: string): object => {
  const decodedParams = decodeURI(qParams)
  const keyValuePairs = decodedParams.split('&')
  const result: {[key: string]: string} = {}

  keyValuePairs.forEach(pair => {
    const [key, value] = pair.split('=')

    if (key && value !== undefined) {
      result[key] = decodeURIComponent(value)
    }
  })

  return result
}

export const isEmptyObjet = (obj: object): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const showTicketType = (type: number): string => {
  if (type === 0) return t('manual_ticket')
  if (type === 1) return t('detractor_alert')
  if (type === 2) return t('customer_feedback')
  return t('direct_email')
}

// Get file type
export const getFileIcon = (fileName: string): IconDefinition => {
  const extension = fileName.split('.').pop()?.toLowerCase() || ''

  switch (extension) {
    case 'pdf':
      return faFilePdf
    case 'doc':
    case 'docx':
      return faFileWord
    case 'xls':
    case 'xlsx':
      return faFileExcel
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return faFileImage
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
      return faFileVideo
    default:
      return faFile
  }
}

export const getKeyNamesWithoutValues = (url: string): string[] => {
  const searchParams = new URLSearchParams(url)

  const paramNamesWithoutValues: string[] = []

  for (const name of Array.from(searchParams.keys())) {
    const value = searchParams.get(name)

    if (!value) {
      paramNamesWithoutValues.push(name)
    }
  }

  return paramNamesWithoutValues
}

export const translate = (key: string): string => {
  return t(key)
}

export const ellipsisDescription = (
  description: string,
  targetLength: number,
  isEllipsis: boolean,
  fillerText = '...',
): string => {
  if (description.length <= targetLength || !isEllipsis) {
    return description
  } else {
    return description.slice(0, targetLength - fillerText.length) + fillerText
  }
}

export const debounce = <Params extends unknown[]>(
  func: (...args: Params) => unknown,
  timeout: number,
): ((...args: Params) => void) => {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError === true
}

// export const getEnumKeyByValue = <T extends Record<string | number, U>, U>(
//   enumObj: T,
//   value: U,
// ): keyof T | undefined => {
//   const keys = Object.keys(enumObj).filter(key => enumObj[key] === value)
//   return keys[0] as keyof T | undefined
// }

// export const getEnumKeyByValue = <T>(
//   enumObj: T,
//   value: number,
// ): keyof T | undefined => {
//   const entry = Object.entries(enumObj).find(([key, val]) => val === value)
//   return entry ? (entry[0] as keyof T) : undefined
// }

export const getEnumKeyByValue = <T extends Record<string, number | string>>(
  enumObj: T,
  value: number | string,
): keyof T | undefined => {
  const keys = Object.keys(enumObj).filter(key => enumObj[key] === value)
  return keys[0] as keyof T | undefined
}
export const emailValidator = new RegExp(
  /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{1,30})+$/,
)

const htmlToString = (input: string): string => {
  return input.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

export const isSurveyLinkExist = (text: string, word: string): boolean => {
  const escapedSurveyLink = htmlToString(word).replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&',
  )
  const regex = new RegExp(escapedSurveyLink)
  return regex.test(text)
}

export const getEnumKeys = <T extends Record<string, string | number>>(
  enumObject: T,
): (keyof T)[] => {
  return Object.keys(enumObject).filter(
    key => !isNaN(Number(enumObject[key as keyof typeof enumObject])),
  )
}

export const generateRandomColor = (): string => {
  const randomRed = Math.floor(Math.random() * 256)
  const randomGreen = Math.floor(Math.random() * 256)
  const randomBlue = Math.floor(Math.random() * 256)

  const color = `rgb(${randomRed}, ${randomGreen}, ${randomBlue})`

  return color
}

export const hexToRgba = (hex: string, alpha: number): string => {
  hex = hex.replace(/^#/, '')

  let bigint = parseInt(hex, 16)
  let r = (bigint >> 16) & 255
  let g = (bigint >> 8) & 255
  let b = bigint & 255

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const getMaxItems = <T>(array: T[], maxItems: number): T[] => {
  return array.slice(0, maxItems)
}

export const localWorkspace = (): Dashboard | undefined => {
  const localWorkspace = localStorage.getItem('workspace')
  if (!localWorkspace) return undefined
  return JSON.parse(localWorkspace)
}

export const hasNumber = (myString: string): boolean => {
  return /\d/.test(myString)
}

export const getAppOrigin = (location: Location): string => {
  const params = new URLSearchParams(location.search)
  const result: Record<string, string> = {}

  params.forEach((value, key) => {
    result[key] = decodeURIComponent(value)
  })

  return result.appOrigin || ''
}
